import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import {animated, interpolate, useSpring} from 'react-spring';
import {useMove} from 'react-use-gesture';

import styles from "./ManifestHover.module.pcss";

const CIRCLE_DIAMETER = 12;

const ManifestHover = ({isVisible}) => {
    const [effectPosition, setEffectPosition] = useSpring(() => ({
        x: 0,
        y: 0,
        config: {tension: 0, friction: 0, precision: 0.1, clamp: true},
    }));

    useMove(({xy: [x, y]}) => {
        setEffectPosition({x: x - 13, y: y - 15});
    }, {domTarget: window, delay: 0});

    return (
        <animated.div
            className={classnames(styles.main, {[styles.loading]: isVisible})}
            style={{
                transform: interpolate([effectPosition.x, effectPosition.y], (xPosition, yPosition) => (
                    `translate3d(${isVisible ? xPosition : 0}px,${isVisible ? yPosition : 0}px,0)`
                )),
            }}
        >
            <svg width={(CIRCLE_DIAMETER * 2)} height={(CIRCLE_DIAMETER * 2)} className={classnames({[styles.loader]: isVisible})}>
                <circle
                    cx={(CIRCLE_DIAMETER)}
                    cy={(CIRCLE_DIAMETER)}
                    r={(CIRCLE_DIAMETER / 2)}
                    className={styles.front}
                    fill="none"
                />
            </svg>
        </animated.div>
    );
};

ManifestHover.propTypes = {
    isVisible: PropTypes.bool.isRequired,
};

export default ManifestHover;
