import {combineReducers} from 'redux';
import {getNextManifestPage, searchManifests} from "./actions";
import {PAGE_SIZE} from "./constants";

const generateTestManifest = (count) => {
    const manifests = [];
    for (let i = 0; i < count; i += 1) {
        manifests.push({id: i, name: `Test - ${i}`, version: "1.0.0"});
    }
    return manifests;
};

const manifests = (state = generateTestManifest(50), {type, payload}) => {
    switch (type) {
        case searchManifests.SUCCESS: {
            return payload.content;
        }
        case getNextManifestPage.SUCCESS: {
            return [...state, ...payload.content];
        }
        default:
            return state;
    }
};

const manifestsPaginationDefault = {page: 0, size: PAGE_SIZE, total: 0, query: ""};
const manifestsPagination = (state = manifestsPaginationDefault, {type, payload}) => {
    switch (type) {
        case getNextManifestPage.SUCCESS:
        case searchManifests.SUCCESS: {
            return {page: payload.page, size: payload.size, total: payload.total, query: payload.query};
        }
        case searchManifests.FAILURE: {
            return manifestsPaginationDefault;
        }
        default:
            return state;
    }
};

export default combineReducers({
    manifests,
    manifestsPagination,
});
