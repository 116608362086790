import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Manifest} from "app/model";

import styles from "./Manifests.module.pcss";
import ManifestComponent from "./Manifest";
import ManifestSkeleton from "./ManifestSkeleton";
import ManifestHover from "./ManifestHover";

const Manifests = ({isFetching, manifests, pagination, getNextPage}) => {
    const [hoverOnManifest, setHoverOnManifest] = useState(false);
    // page starts with 0
    const hasMorePages = pagination.total > 0 && pagination.total > ((pagination.page + 1) * pagination.size);

    const createSkeleton = () => {
        const SKELETON_COUNT = 3;
        const skeleton = [];
        for (let i = 0; i < SKELETON_COUNT; i += 1) {
            skeleton.push(<ManifestSkeleton key={i} />);
        }
        return skeleton;
    };
    return (
        <>
            <ManifestHover isVisible={hoverOnManifest} />
            <div className={styles.main}>
                {isFetching ? createSkeleton() : manifests.map(({id, name, version}) => (
                    <ManifestComponent
                        key={id}
                        name={name}
                        version={version}
                        setHoverOnManifest={setHoverOnManifest}
                    />
                ))}
            </div>
            {hasMorePages && (
                <div className={styles.more}>
                    <button type="button" onClick={getNextPage}>Give me more</button>
                </div>
            )}
        </>
    );
};

Manifests.propTypes = {
    manifests: PropTypes.arrayOf(Manifest.modelPropTypes),
    isFetching: PropTypes.bool,
    pagination: PropTypes.shape({
        page: PropTypes.number,
        size: PropTypes.number,
        total: PropTypes.number,
    }).isRequired,
    getNextPage: PropTypes.func.isRequired,
};

Manifests.defaultProps = {
    manifests: [],
    isFetching: false,
};

export default Manifests;
