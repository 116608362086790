import React, {useRef, memo, useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import ManifestDetail from "./ManifestDetail";
import {getBoundingFromRef} from "./utils";

import styles from "./Manifest.module.pcss";

const Manifest = memo(({name, version, setHoverOnManifest}) => {
    const manifestHoverTimeoutRef = useRef(null);
    const manifestDetailTimeoutRef = useRef(null);

    const manifestRef = useRef(null);

    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [hovering, setHovering] = useState(false);

    useEffect(() => {
        if (hovering) {
            addMouseMoveListener();
            manifestHoverTimeoutRef.current = setTimeout(() => {
                setHoverOnManifest(true);
                openDetail();
            }, 300);
        } else {
            clearHoverTimeout();
            setHoverOnManifest(false);
            setIsDetailOpen(false);
            removeMouseMoveListener();
        }
    }, [hovering]);

    const checkMousePosition = useCallback((e) => {
        const {x, y} = e;
        const {top, left, right, bottom} = getBoundingFromRef(manifestRef);
        if (y < top || y > bottom || x < left || x > right) {
            setHovering(false);
        }
    }, []);

    const addMouseMoveListener = () => {
        window.addEventListener("mousemove", checkMousePosition);
    };

    const removeMouseMoveListener = () => {
        window.removeEventListener("mousemove", checkMousePosition);
    };

    const openDetail = () => {
        manifestDetailTimeoutRef.current = setTimeout(() => {
            setIsDetailOpen(true);
        }, 1000);
    };

    const clearHoverTimeout = () => {
        clearTimeout(manifestHoverTimeoutRef.current);
        clearTimeout(manifestDetailTimeoutRef.current);
    };

    return (
        <div
            className={classnames(styles.wrapper, {[styles.open]: isDetailOpen})}
            ref={manifestRef}
            onMouseEnter={() => setHovering(true)}
        >
            <div className={classnames(styles.main)}>
                <h3>{name}</h3>
                {version && <p className={styles.version}>{`Version: ${version}`}</p>}
            </div>
        </div>
    );
});

Manifest.propTypes = {
    name: PropTypes.string.isRequired,
    version: PropTypes.string,
    setHoverOnManifest: PropTypes.func.isRequired,
};

Manifest.defaultProps = {
    version: null,
};

export default Manifest;
